"use client"; // Marks this as a client-side component
import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import {
  AcademyHeader,
  ContentContainer,
  Layout,
  Stack,
  SubHeading,
  TP,
} from "./molecules";
import Vector3Shape from "../assets/svg/Vector3Shape";
import { getWhyUs } from "../actions/clientSide";
import AcademySkeleton from "../components/skeletons/AcademySkeleton"; // Skeleton component for loading state

const AcademyCarousel = dynamic(() => import("../components/AcademyCarousel"));

const Academy = ({ className, params }) => {
  const [data, setData] = useState(null); // State to hold fetched data
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true before fetching
        const response = await getWhyUs(params);
        setData(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
  }, [params]);

  if (loading) {
    // Render skeleton while loading
    return (
      <Layout className={`academy ${className}`}>
        <ContentContainer>
          <Stack>
            <AcademySkeleton /> {/* Show loading skeleton */}
          </Stack>
        </ContentContainer>
      </Layout>
    );
  }

  return (
    <>
      {data && (
        <Layout className={`academy ${className}`}>
          <ContentContainer>
            <Stack>
              <AcademyHeader className="mb-16 flex justify-between gap-4 md:items-end flex-col md:flex-row items-start md:gap-20">
                <div>
                  <Vector3Shape />
                  <SubHeading className="text-[32px] lg:text-5xl">
                    {data?.title}
                  </SubHeading>
                </div>
                <TP className="text-start md:text-end">{data?.description}</TP>
              </AcademyHeader>
            </Stack>
          </ContentContainer>
          <Stack className="px-4 lg:ps-28">
            <AcademyCarousel data={data?.details || []} />
          </Stack>
        </Layout>
      )}
    </>
  );
};

export default Academy;
