"use client";
import { Input } from "@nextui-org/react";
import React from "react";
import { useDictionary } from "../../context/dictionaryProvider";

const InputTextField = React.forwardRef(
  ({ label, isInvalid, errorMessage, ...rest }, ref) => {
    const dict = useDictionary();
    
    return (
      <div className="mb-4 ">
        <Input
          placeholder={dict?.form?.placeholders?.typeHere}
          id="inputRef"
          label={label}
          labelPlacement="outside-left"
          ref={ref}
          {...rest}
          variant="bordered"
          isInvalid={isInvalid}
          color={isInvalid ? "#000" : "red"}
          errorMessage={errorMessage}
          classNames={{
            label: `block text-sm font-semibold leading-6 !text-black-900 mb-1 capitalize p-0`,
            input: "contactInput",
            inputWrapper: "p-0 border-none shadow-none",
            base: "flex-col items-start",
            mainWrapper: "w-full",
            helperWrapper: "p-0 ",
            errorMessage: "text-red-500 first-letter:capitalize",
            isInvalid: "text-red-500",
          }}
        />
      </div>
    );
  }
);

InputTextField.displayName = "InputTextField";

export default InputTextField;
