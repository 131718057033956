import endpoints from "../../lib/endpoints";
import { getRequestNew } from "../../lib/fetchClient";
import { baseUrl } from "../../lib/util";

export const getRelatedCourse = async (params, category = "") => {
  try {
    let url = baseUrl + endpoints.relatedCourse;

    if (category != "") {
      url += "&filters[category][slug][$eq]=" + category;
    } else {
      url += "&filters[in_demand][$eq]=true";
    }

    return await getRequestNew(
      url,
      {
        lang: params?.lang,
      },
      true,
      false
    );
  } catch (error) {
    console.log(error);
  }
};

export const getClients = async (params) => {
  try {
    return await getRequestNew(
      baseUrl + endpoints.getClients,
      {
        lang: params?.lang,
      },
      true,
      false
    );
  } catch (error) {
    console.log(error);
  }
};

export const getWhyUs = async (params) => {
  try {
    return await getRequestNew(
      baseUrl + endpoints.getWhyUs,
      {
        lang: params?.lang,
      },
      true,
      false
    );
  } catch (error) {
    console.log(error);
  }
};

export const getFooterMenus = async (params) => {
  try {
    return await getRequestNew(
      baseUrl + endpoints.footerMenus,
      {
        lang: params?.lang,
      },
      true,
      false
    );
  } catch (error) {
    console.log(error);
  }
};

export const getHeaderMenus = async (params) => {
  try {
    return await getRequestNew(
      baseUrl + endpoints.headerMenus,
      {
        lang: params?.lang,
      },
      true,
      false
    );
  } catch (error) {
    console.log(error);
  }
};
