import React from "react";

const AcademySkeleton = () => {
  return (
    <div className="animate-pulse flex flex-col gap-4">
      <div className="w-full h-8 bg-gray-300 rounded-md"></div>
      <div className="w-1/2 h-6 bg-gray-300 rounded-md"></div>
      <div className="w-full h-48 bg-gray-300 rounded-md"></div>
      <div className="w-3/4 h-4 bg-gray-300 rounded-md"></div>
    </div>
  );
};

export default AcademySkeleton;
