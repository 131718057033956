"use client";
import { useParams } from "next/navigation";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useDictionary } from "../../context/dictionaryProvider";

const PhoneField = ({
  label,
  isInvalid,
  errorMessage,
  name,
  value,
  onChange,
}) => {
  const dict = useDictionary();

  let currentCountry = "AE";
  if (typeof window !== "undefined") {
    currentCountry = localStorage?.getItem("countryCode") ?? "AE";
  }

  const params = useParams();

  return (
    <div className="mb-4" data-nosnippet>
      <label className="block text-sm font-semibold leading-6 text-black-900 mb-1 capitalize">
        {label}
      </label>
      <PhoneInput
        name={name}
        placeholder={dict?.form?.placeholders?.typeHere}
        className="border-b-1"
        inputClassName={`${
          params?.lang === "ar" ? "text-end" : ""
        } !border-none`}
        value={value}
        onChange={onChange}
        defaultCountry={currentCountry?.toLowerCase() ?? "ae"}
        countrySelectorStyleProps={{
          buttonClassName: "!border-none",
          dropdownStyleProps: {
            className: `${
              params?.lang === "ar" ? "left-auto right-0" : ""
            } !z-[999] w-full`,
          },
        }}
      />
      {errorMessage && isInvalid && (
        <div className="group-data-[has-helper=true]:flex relative flex-col gap-1.5 p-0 mt-2">
          <div className="text-tiny text-red-500">{errorMessage}</div>
        </div>
      )}
    </div>
  );
};

PhoneField.displayName = "PhoneField";

export default PhoneField;
